import * as React from 'react';
import { createStyles, WithStyles, withStyles, Snackbar, Typography, Button } from '@material-ui/core';
import { tgrey100 } from '../../styles/colors';
import { apiUrl } from '../../actions/server';
import Header from '../Header/Header';
import textConstants from '../../actions/constants';
import { AppState, UserLang } from '../../Models/AppState';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps } from 'react-router';

const styles = createStyles({
  mainHeader: {
    display: 'flex',
    'justify-content': 'space-between',
    padding: '15px 24px 15px 24px',
    backgroundColor: tgrey100
  },
  wrapperLoginForm: {
    marginTop: '5em'
  },
  formWrapper: {
    width: 500,
    margin: 'auto',
  },
  formTitle: {
    margin: 0,
    marginBottom: 30,
    fontSize: 20,
    fontWeight: 600
  },
});

interface Props extends WithStyles<typeof styles> {}

interface State {
  licenseString: string;
  licenseStringRevoke: string;
  snackOpen: boolean;
  snackMessage: string;
}

class LicenseUploadCnt extends React.Component<Props & MSTP & RouteComponentProps & DispatchProp, State> {
  state: State = {
    licenseString: '',
    licenseStringRevoke: '',
    snackOpen: false,
    snackMessage: '',
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.has('lang')) {
      let languageFromUrl = urlParams.get('lang');
      if (languageFromUrl) {
        languageFromUrl = languageFromUrl.toUpperCase();
        if (languageFromUrl === UserLang.ENG || languageFromUrl === UserLang.RUS) {
          this.props.dispatch({type: 'SET_USER_LANG', payload: languageFromUrl});
        }
      }
    }
  }

  handleUploadLicense = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      this.setState({licenseString: ''});
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      this.setState({licenseString: String(reader.result)});
    }
  }

  handleUploadRevoke = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (!file) {
      this.setState({licenseStringRevoke: ''});
      return;
    }

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      this.setState({licenseStringRevoke: String(reader.result)});
    }
  }

  handleSubmit = () => {
    const { licenseString } = this.state;
    if (!licenseString) {
      return;
    }

    fetch(`${apiUrl}/api/license/activateLicense`, {
      method: 'POST',
      body: licenseString,
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(resp => {
      if (!resp.ok) {
        throw Error(resp.statusText);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(json => {
      if (json.hasOwnProperty('errors')) {
        throw new Error(json.errors);
      }
      return json;
    })
    .then((json: Object) => {
      this.downloadLicense(JSON.stringify(json));
    })
    .catch(err => {
      console.log(err.message);
      let errorText = 'Произошла Ошибка';

      switch (err.message) {
        case 'system_id_is_not_matching_existing_id':
          errorText = textConstants['RUS']['error_license_already_active'];
          break;
        case 'max_activations_for_single_license':
          errorText = textConstants['RUS']['error_license_cant_activate'];
          break;
        case 'license_is_deactivated':
          errorText = textConstants['RUS']['error_license_is_deactivated'];
          break;
        default:
          break;
      }
      this.setState({snackMessage: errorText, snackOpen: true});
    });
  }

  handleSubmitRevoke = () => {
    const { licenseStringRevoke } = this.state;
    if (!licenseStringRevoke) {
      return;
    }

    fetch(`${apiUrl}/api/license/revokeLicense`, {
      method: 'POST',
      body: licenseStringRevoke,
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(resp => {
      if (!resp.ok) {
        throw Error(resp.statusText);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(json => {
      if (json.errors) {
        throw new Error(json.errors);
      } else {
        this.setState({snackMessage: 'Ваша лицензия деактивирована', snackOpen: true});
      }
    })
    .catch(err => {
      this.setState({snackMessage: 'Произошла Ошибка', snackOpen: true});
    });
  }

  getLicenseNumberFromProductCode = (data: string): string => {
    const parsed = JSON.parse(data);
    return parsed.licenseNumber;
  }

  downloadLicense = (data: string) => {
    const a = window.document.createElement('a');
    if (!data) {
      return;
    }
    const licenseNumber = this.getLicenseNumberFromProductCode(data);
    const fileName = `activation_key_${licenseNumber}.txt`;
    
    a.href = window.URL.createObjectURL(new Blob([data], {type: 'text/plain'}));
    a.download = fileName;
    
    // Append anchor to body.
    document.body.appendChild(a);
    a.click();
    
    // Remove anchor from body
    document.body.removeChild(a);
  }

  handleSnackClose = () => {
    this.setState({snackOpen: false});
  }

  render() {
    const { classes, userLang } = this.props;
    return(
      <div>
        <Header />
        <section className={classes.wrapperLoginForm}>
          <form className={classes.formWrapper}>
            <Typography variant="h5" style={{marginBottom: '1em'}}>{textConstants[userLang]['upload_product_code_file']}</Typography>
            <input
              type="file"
              onChange={this.handleUploadLicense}
              accept=".txt"
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.licenseString}
              onClick={this.handleSubmit}
              style={{margin: '1em'}}
            >
              {textConstants[userLang]['activate']}
            </Button>
          </form>
        </section>
        <section className={classes.wrapperLoginForm}>
          <form className={classes.formWrapper}>
            <Typography variant="h5" style={{marginBottom: '1em'}}>{textConstants[userLang]['upload_deactivation_file']}</Typography>
            <input
              type="file"
              onChange={this.handleUploadRevoke}
              accept=".txt"
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.licenseStringRevoke}
              onClick={this.handleSubmitRevoke}
              style={{margin: '1em'}}
            >
              {textConstants[userLang]['deactivate']}
            </Button>
          </form>
        </section>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: AppState) => {
  const userLang = state.mainReducer.userLang;
  return {
    userLang: userLang,
  };
}

type MSTP = ReturnType<typeof mapStateToProps>;

export default withStyles(styles)(connect(mapStateToProps)(LicenseUploadCnt));