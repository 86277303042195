import * as React from 'react';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { tgreen100, tgreyBD, tgreyBG, tred60 } from '../../styles/colors';
import { renderTextField } from '../Common/FormCommon';
import { Typography, Button } from '@material-ui/core';

const formStyles = createStyles({
  formTitle: {
    margin: 0,
    marginBottom: '30px',
    fontSize: '20px',
    fontWeight: 600
  },
  formWrapper: {
    width: '400px',
    margin: 'auto',
  },
  submitBtn: {
    marginTop: '1em',
  },
  formInput: {
    width: '400px',
    height: '36px',
    boxSizing: 'border-box',
    padding: '0px 16px',
    border: `1px solid ${tgreyBD}`,
    borderRadius: '2px',
    backgroundColor: tgreyBG,
    // fontFamily: 'Open Sans, Arial, sans-serif',
    fontSize: '13px',
    transition: 'all 0.5s ease-out',
    '&:focus': {
      border: `2px solid ${tgreen100}`,
      boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
    },
    '&:last-child': {
      marginTop: '15px'
    },
  },
  formInputError: {
    border: `2px solid ${tred60}`,
    boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
  }
});

interface StyledForm extends WithStyles<typeof formStyles> {}

export interface ValuesConfig {
  loginUser: string;
  loginPassword: string;
}


export const LoginFormCmp: React.SFC<StyledForm & InjectedFormProps<ValuesConfig>> = props => {
  const { handleSubmit, classes } = props;
  return (
    <form className={classes.formWrapper} onSubmit={handleSubmit} autoComplete="off">
      <Typography variant="h5">Логин</Typography>
      <Field
        type="text"
        name="loginUser"
        component={renderTextField}
        placeholder="Email"
        style={classes}
      />
      <Field
        name="loginPassword"
        type="password"
        component={renderTextField}
        placeholder="Пароль"
        style={classes}
      />
      <Button variant="contained" color="primary" className={classes.submitBtn} type="submit">Отправить</Button>
    </form>
  );
};

export default reduxForm<ValuesConfig>({form: 'login'})(withStyles(formStyles)(LoginFormCmp));