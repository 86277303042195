import * as React from 'react';
import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@material-ui/core';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleLeave: () => void;
}

const ConfirmLeaveDialog: React.SFC<Props> = props => {
  const { open, handleClose, handleLeave } = props;
  return(
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Изменения не сохранены</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Вы действительно хотите выйти? Эта лицензия не будет сохранена.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Отмена
        </Button>
        <Button
          onClick={handleLeave}
        >
          Продолжить
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmLeaveDialog;