import * as React from 'react';
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { tgreySUB, tgreyBD, tgreyBG, tgreen100 } from "../../styles/colors";
import { InjectedFormProps, Field, reduxForm } from "redux-form";
import { License } from "../../Models/AppState";
import { renderTextField } from "../Common/FormCommon";
import { requiredStr } from '../../validation';

const styles = createStyles({
  formList: {
    padding: 0
  },
  formListItem: {
    display: 'flex',
    'align-items': 'center',
  },
  formSubTitle: {
    width: '170px',
    fontSize: '13px',
    color: tgreySUB
  },
  formFieldDialog: {
    fontSize: '13px'
  },
  formInput: {
    width: '400px',
    height: '30px',
    marginTop: '22px',
    boxSizing: 'border-box',
    padding: '0px 16px',
    border: `1px solid ${tgreyBD}`,
    borderRadius: '2px',
    backgroundColor: tgreyBG,
    fontSize: '13px',
    transition: 'all 0.5s ease-out',
    '&:focus': {
      border: `2px solid ${tgreen100}`,
      boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
    },
  },
});

interface StyledForm extends WithStyles<typeof styles> { }

const EditLicenseFormCmp: React.SFC<StyledForm & InjectedFormProps<License>> = props => {
  const { classes, handleSubmit } = props;
  return (
    <form id="formChangeClient" onSubmit={handleSubmit} autoComplete="off">
      <ul className={classes.formList}>
        <li className={classes.formListItem}>
          <p className={classes.formSubTitle}>Клиент</p>
          <div className={classes.formFieldDialog}>
            <Field
              name="companyName"
              type="text"
              component={renderTextField}
              style={classes}
              validate={[requiredStr]}
            />
          </div>
        </li>

        <li className={classes.formListItem}>
          <p className={classes.formSubTitle}>Парт номер</p>
          <div className={classes.formFieldDialog}>
            <Field
              name="partNumber"
              type="text"
              component={renderTextField}
              style={classes}
              validate={[requiredStr]}
            />
          </div>
        </li>
      </ul>
    </form>
  );
};

export default reduxForm<License>({form: 'changeMAC'})(withStyles(styles)(EditLicenseFormCmp));