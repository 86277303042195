import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { getAllLicenses, apiUrl } from '../../actions/server';
import {
  withStyles,
  IconButton,
  Button,
  createStyles,
  WithStyles,
  Typography,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import { tgreyM } from '../../styles/colors';
import Header from '../Header/Header';
import { License, AppState } from '../../Models/AppState';
import NewLicenseForm from './NewLicenseForm';
import ConfirmLeaveDialog from './ConfirmLeaveDialog';
import { RouteComponentProps } from 'react-router-dom';

const styles = createStyles({
  formWrapper: {
    padding: '0 25px'
  },
  navigationWrapper: {
    display: 'flex',
    'justify-content': 'space-between',
    'align-items': 'center',
    padding: '20px 24px 0px 10px'
  },
  backWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    color: tgreyM
  },
  iconSave: {
    marginRight: '10px'
  },
});

interface NewLicenseProps extends WithStyles<typeof styles> { }

type AllProps = NewLicenseProps & DispatchProp & RouteComponentProps & MSTP;
interface NewLicenseState {
  open: boolean;
  snackOpen: boolean;
  snackMessage: string;
  isSavingLicense: boolean;
}

class NewLicense extends React.Component<AllProps, NewLicenseState> {
  state: NewLicenseState = {
    open: false,
    snackOpen: false,
    snackMessage: '',
    isSavingLicense: false,
  };

  handleClickOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleLogOut = () => {
    this.handleClose();
    this.props.history.replace('/list');
  }

  handleSubmit = (values: License) => {
    this.setState({isSavingLicense: true});
    const token = localStorage.getItem('token');
    const setExpirationDateToEndOfDay = new Date(new Date(values.expirationDate).setUTCHours(23, 59, 59, 0));
    const license = {...values, expirationDate: setExpirationDateToEndOfDay};

    return fetch(`${apiUrl}/api/license/addLicense`, {
      method: 'POST',
      headers: { 
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(license),
    })
    .then(resp => {
      if (!resp.ok) {
        throw Error(resp.statusText);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(json => {
      if (json.hasOwnProperty('errors')) {
        throw new Error(json.errors);
      }
      return json;
    })
    .then(json => {
      getAllLicenses(this.props.dispatch, this.props.history)
      .then(() => {
        this.setState({isSavingLicense: false});
        this.props.history.replace(`/license/${json.id}`);
      })
    })
    .catch(error => {
      this.setState({snackMessage: 'Ошибка при добавлении лицензии', snackOpen: true, isSavingLicense: false});
    });
  }

  handleSnackClose = () => {
    this.setState({snackOpen: false, snackMessage: ''});
  }

  render() {
    const { classes } = this.props;
    const { isSavingLicense } = this.state;
    return (
      <div>
        <Header />

        <section className={classes.navigationWrapper}>
          <div className={classes.backWrapper}>
            <IconButton onClick={this.handleClickOpen}>
              <ArrowBackIcon className={classes.arrowIcon} />
            </IconButton>
            <Typography variant="h5">Добавление лицензии</Typography>
          </div>

          <Button form="formNewLicense" type="submit" variant="contained" color="primary" disabled={isSavingLicense}>
            {isSavingLicense ?
              <CircularProgress
                color="secondary"
                size={20}
                thickness={4}
                disableShrink
                variant="indeterminate"
                style={{marginRight: 10}}
              />
                :
              <SaveIcon className={classes.iconSave} />
            }
            Сохранить
          </Button>

        </section>

        <section className={classes.formWrapper}>
          <NewLicenseForm
            initialValues={{camAmount: 1, companyName: '', expirationDate: new Date(new Date().getTime() + 8.64e+7).toISOString().split("T")[0]}}
            user={this.props.user}
            onSubmit={this.handleSubmit}
          />
        </section>

        <ConfirmLeaveDialog
          open={this.state.open}
          handleClose={this.handleClose}
          handleLeave={this.handleLogOut}
        />
        
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.mainReducer.loggedUser,
    licenses: state.mainReducer.licenses,
  };
};

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(withStyles(styles)(NewLicense));
