import { SubmissionError } from 'redux-form';

interface ValuesConfig {
  loginUser: string;
  loginPassword: string;
}

const validateLogin = (values: ValuesConfig) => {
  if (!values.loginUser) {
    throw new SubmissionError({
      loginUser: 'Это поле обязательно для заполнения',
      _error: 'Login failed',
    });
  }

  if (!values.loginPassword) {
    throw new SubmissionError({
      loginPassword: 'Это поле обязательно для заполнения',
      _error: 'Login failed',
    });
  }
}

export default validateLogin;
