import * as React from 'react';
import { Switch, Route, Router } from 'react-router';
import withRoot from '../../withRoot';
import { history } from '../../index';
import Login from '../Login/LoginCnt';
import EditLicense from '../EditLicense/EditLicenseCnt';
import LicenseUploadCnt from '../UploadLicense/LicenseUploadCnt';
import NotFound from '../Common/NotFound';
import List from '../LicenseTable/List';
import NewLicense from '../AddLicense/NewLicenseCnt';
import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/list" component={List} />
      <PrivateRoute exact path="/add_license" component={NewLicense} />
      <PrivateRoute exact path="/license/:id" component={EditLicense} />
      <Route exact path="/upload" component={LicenseUploadCnt} />
      <Route exact component={NotFound} />
    </Switch>
  </Router >
);

export default withRoot(Routes);