interface TableReducerState {
  [name: string]: {
    filters: Array<any>,
    page: number;
  }
}

interface TableAction {
  type: string,
  payload: any;
}

const INITIAL_STATE: TableReducerState = { };

export default function tableReducer(state = INITIAL_STATE, action: TableAction) {
  switch (action.type) {
    case 'init-table': {
      if (action.payload.persistent && !state[action.payload.name]) {
        return {
          [action.payload.name]: {
            page: action.payload.page,
            filters: action.payload.filters,
            order: action.payload.order,
            orderBy: action.payload.orderBy,
          },
        }
      } else {
        return state;
      }
    }
    case 'set_page': {
      return {
        [action.payload.name]: {...state[action.payload.name], page: action.payload.page},
      }
    }
    case 'filter': {
      const { value, selector, type } = action.payload;
      const foundFilter = state[action.payload.name].filters.find(s => s.selector === selector);

      if (foundFilter && value) {
        // change existing filter
        foundFilter.value = value;
        return {
          [action.payload.name]: {...state[action.payload.name], filters: state[action.payload.name].filters.map(f => f.selector === selector ? { value: value, selector: selector, type } : f)},
        }
      } else if (foundFilter && !value) {
        // remove existing filter
        return {
          [action.payload.name]: {...state[action.payload.name], filters: state[action.payload.name].filters.filter(f => f.selector !== selector)},
        }
      } else if (!foundFilter && value) {
        // add new filter
        return {
          [action.payload.name]: {...state[action.payload.name], filters: state[action.payload.name].filters.concat({ value, selector, type })},
        }
      }
    }
    case 'sort': {
      return {
        [action.payload.name]: {...state[action.payload.name], order: action.payload.order, orderBy: action.payload.orderBy}
      };
    }
    default:
      return state;
  }
}