import * as React from 'react';
import {
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';

type RenderInputProps = {
  style?: any;
  input?: React.Component;
  placeholder?: string;
  disabled?: boolean;
  type: string;
  connectionField?: boolean;
};

export const renderTextField = (props: RenderInputProps & WrappedFieldProps) => {
  const {meta, input, style, type, connectionField, ...custom} = props;
  return (
    <FormControl error={!!meta.error && meta.touched}>
      <TextField
        type={type}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: !!meta.error && meta.touched ? `${style.formInput} ${style.formInputError}` : style.formInput,
          },
        }}
        {...input}
        {...custom}
      />
      <FormHelperText id="name-error-text">{meta.error}</FormHelperText>
    </FormControl>
  );
};