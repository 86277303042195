import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  withStyles,
  IconButton,
  Button,
  WithStyles,
  createStyles,
  Typography,
  Snackbar
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import { tgreyM, tgreySUB, tred60 } from '../../styles/colors';
import Header from '../Header/Header';
import { License, AppState, PublicLicense, LicenseStatus } from '../../Models/AppState';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { apiUrl, getAllLicenses } from '../../actions/server';
import { formatDate } from '../../actions/utils';
import { RouteComponentProps } from 'react-router';
import EditLicenseForm from './EditLicenseForm';
import textConstants from '../../actions/constants';
import EditLicenseModal from './EditLicenseModal';

const styles = createStyles({
  pageCntWrap: {
    margin: '1em',
  },
  navigationWrapper: {
    display: 'flex',
  },
  backWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    color: tgreyM
  },
  formWrapper: {
    padding: '0 25px'
  },
  formList: {
    padding: 0
  },
  formListItem: {
    display: 'flex',
    'align-items': 'center',
  },
  formSubTitle: {
    width: '170px',
    fontSize: '13px',
    color: tgreySUB
  },
  formSubTitleUsers: {
    width: '152px'
  },
  formField: {
    minWidth: '190px',
    fontSize: '13px'
  },
  editWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: tred60
  },
  editBtn: {
    color: tred60
  },
  editText: {
    marginLeft: '10px',
    fontSize: '14px',
    fontWeight: 600
  },
  btnChange: {
    color: tred60
  },
  formInputError: {
    border: `2px solid ${tred60}`,
    boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
  },
  formInputMAC: {
    width: '385px'
  },
  iconSave: {
    marginRight: 8,
    fontSize: 20,
  }
});

interface EditLicenseProps extends WithStyles<typeof styles> { }

type AllProps = EditLicenseProps & DispatchProp & RouteComponentProps<{id: string}> & MSTP;
interface EditLicenseState {
  editLicenseOpen: boolean;
  snackOpen: boolean;
  snackMessage: string;
}

export class EditLicense extends React.Component<AllProps, EditLicenseState> {
  state: EditLicenseState = {
    editLicenseOpen: false,
    snackOpen: false,
    snackMessage: '',
  };

  handleBack = () => {
    this.props.history.push('/list');
  }

  handleOpenEditLicense = () => {
    this.setState({editLicenseOpen: true});
  }

  handleSnackClose = () => {
    this.setState({snackOpen: false});
  }

  handleCloseEditLicense = () => {
    this.setState({editLicenseOpen: false});
  }

  getPubKey = async (uuid: string) => {
    const resp = await fetch(`${apiUrl}/api/license/getPubKey?uuid=${uuid}`);
    if (!resp.ok) {
      throw new Error(resp.statusText);
    }
    return await resp.text();
  }

  generateLicenseObject = (license: License, pubKey: string) => {
    return {
      licenseNumber: license.uuid,
      camNumber: license.camAmount,
      expirationDate: license.expirationDate,
      key: pubKey,
    };
  }

  downloadLicense = (publicLicense: PublicLicense): void => {
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob([JSON.stringify(publicLicense)], {type: 'text/plain'}));
    a.download = `${publicLicense.licenseNumber}.txt`;
    document.body.appendChild(a);
    a.click();
    
    // Remove anchor from body
    document.body.removeChild(a);
  }

  downloadLicenseClick = async () => {
    const data = this.props.currentLicense;
    if (!data) {
      return;
    }

    try {
      const pubKey = await this.getPubKey(data.uuid);
      const publicLicense = this.generateLicenseObject(data, pubKey);
      this.downloadLicense(publicLicense);
    } catch(err) {
      this.setState({snackOpen: true, snackMessage: 'Произошла ошибка'});
    }
  }

  deactivateLicense = async () => {
    const { currentLicense } = this.props;
    const token = localStorage.getItem('token');
    try {
      const resp = await fetch(`${apiUrl}/api/license/DeactivateLicense`, {
        method: 'POST',
        headers: { 
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(currentLicense),
      });
      const json = await resp.json();

      if (json.hasOwnProperty('errors')) {
        throw new Error(json.errors);
      }

      getAllLicenses(this.props.dispatch, this.props.history);
    } catch (err) {
      this.setState({snackMessage: 'Произошла ошибка', snackOpen: true});
    }
  }

  onSubmit = (formValue: License) => {
    const token = localStorage.getItem('token');

    fetch(`${apiUrl}/api/license/EditLicense`, {
      method: 'PUT',
      body: JSON.stringify(formValue),
      headers: { 
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    })
    .then(resp => {
      if(!resp.ok) {
        throw new Error(resp.statusText);
      }
      return resp;
    })
    .then(resp => resp.json())
    .then(json => {
      if (json.errors) {
        throw new Error(json.errors);
      }
      return json;
    })
    .then(editedLicense => {
      getAllLicenses(this.props.dispatch, this.props.history);
      this.setState({editLicenseOpen: false});
    })
  }

  render() {
    const { classes, currentLicense } = this.props;
    const { editLicenseOpen } = this.state;

    if (!currentLicense) {
      return('Loading...');
    }

    return (
      <div>
        <Header />
        <div className={classes.pageCntWrap}>
          <section className={classes.navigationWrapper}>
            <div className={classes.backWrapper}>
              <IconButton onClick={this.handleBack}>
                <ArrowBackIcon className={classes.arrowIcon} />
              </IconButton>
              <Typography variant="h5">Лицензия</Typography>
            </div>

            <Button onClick={this.downloadLicenseClick} variant="contained" color="primary" style={{marginLeft: 'auto'}}>
              <SaveIcon className={classes.iconSave} />
              Скачать
            </Button>

            <Button onClick={this.handleOpenEditLicense} variant="contained" color="default" style={{marginLeft: '1em'}}>
              <EditIcon />
            </Button>

            <Button
              onClick={this.deactivateLicense}
              variant="contained"
              color="secondary"
              style={{marginLeft: '1em'}}
              disabled={
                currentLicense.status === LicenseStatus.DEACTIVATED_WITHOUT_KEY
                ||
                currentLicense.status === LicenseStatus.DEACTIVATED
                ||
                currentLicense.status === LicenseStatus.NOT_ACTIVATED
              }
            >
              <CloseIcon className={classes.iconSave} />
              Деактивировать
            </Button>
          </section>

          <section className={classes.formWrapper}>
            <ul className={classes.formList}>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>UUID</p>
                <div className={classes.formField}>{currentLicense.uuid}</div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Кол-во камер</p>
                <div className={classes.formField}>{currentLicense.camAmount}</div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Статус</p>
                <div className={classes.formField}>
                  {textConstants['RUS'][currentLicense.status]}
                </div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Дата создания</p>
                <div className={classes.formField}>{formatDate(currentLicense.createDate)}</div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Активна до</p>
                <div
                  className={classes.formField}
                  style={{
                    color: currentLicense.status === LicenseStatus.ACTIVATED &&
                      new Date().getTime() > new Date(currentLicense.expirationDate).getTime() ?
                      'red' : ''
                      }}>
                  {formatDate(currentLicense.expirationDate)}
                </div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Дата активации</p>
                <div className={classes.formField}>
                  {currentLicense.isActive ? formatDate(currentLicense.activationDate) : 'N/A'}
                </div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Клиент</p>
                <div className={classes.formField}>
                  {currentLicense.companyName}
                </div>
              </li>

              <li className={classes.formListItem}>
                <p className={classes.formSubTitle}>Парт номер</p>
                <div className={classes.formField}>
                  {currentLicense.partNumber}
                </div>
              </li>

            </ul>
          </section>
        </div>

        <EditLicenseModal
          open={editLicenseOpen}
          handleClose={this.handleCloseEditLicense}
        >
          <EditLicenseForm
            initialValues={currentLicense}
            onSubmit={this.onSubmit}
          />
        </EditLicenseModal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          message={<span id="message-id">{this.state.snackMessage}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, OwnProps: RouteComponentProps<{id: string}>) => {
  const currentLicenseId = OwnProps.match.params.id;
  const currentLicense = state.mainReducer.licenses.find(l => l.id === Number(currentLicenseId));
  return {
    currentLicense,
  };
};

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(withStyles(styles)(EditLicense));
