import * as React from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { complitechBlue } from './styles/colors';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ru } from 'date-fns/locale';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: complitechBlue,
    }
  },
  typography: {
    useNextVariants: true,
  },
});

const decorate = withStyles({
  '@global': {
    body: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  }
});

const AppWrapper = decorate((props: any) => props.children);

function withRoot<P>(Component: React.ComponentType<P>) {
  function WithRoot(props: P) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AppWrapper>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ru}>
            <Component {...props} />
          </MuiPickersUtilsProvider>
        </AppWrapper>
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
