import { MainReducerState, Action, UserLang } from "../Models/AppState";

const INITIAL_STATE: MainReducerState = {
  licenses: [],
  loggedUser: null,
  allUsers: [],
  isInitialized: false,
  userLang: UserLang.RUS,
};

export default function mainReducer(state = INITIAL_STATE, action: Action): MainReducerState {
  switch (action.type) {
    case 'GET_ALL_LICENSES': {
      return {...state, licenses: action.payload};
    }
    case 'INIT_USER': {
      return {...state, loggedUser: action.payload};
    }
    case 'GET_ALL_USERS': {
      return {...state, allUsers: action.payload};
    }
    case 'DATA_INITIALIZED': {
      return {...state, isInitialized: action.payload};
    }
    case 'SET_USER_LANG': {
      return {...state, userLang: action.payload};
    }
    default:
      return state;
  }
}