import * as React from 'react';

export const TableParamsCtx = React.createContext({name: ''});

function withTable<AddProps>(params: {name: string}, Cmp: React.ComponentType<AddProps>) {
  class Tmp extends React.Component<AddProps> {
    render() {
      return(
        <TableParamsCtx.Provider value={params}>
          <Cmp {...this.props} />
        </TableParamsCtx.Provider>
      );
    }
  }

  return Tmp;
}

export default withTable;