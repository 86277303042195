const textConstants = {
  RUS: {
    'error_license_already_active': 'Данная лицензия была активирована ранее. Свяжитесь с технической поддержкой',
    'error_license_cant_activate': 'Не удалось активировать лицензию. Свяжитесь с технической поддержкой',
    'error_activation_key_not_valid': 'Файл активации не подходит для данной лицензии',
    'error_license_is_deactivated': 'Не удалось активировать лицензию. Cвяжитесь с технической поддержкой',
    'ACTIVATED': 'Активна',
    'NOT_ACTIVATED': 'Не активна',
    'DEACTIVATED': 'Деактивированна',
    'DEACTIVATED_WITHOUT_KEY': 'Деактивированна без ключа',
    'upload_product_code_file': 'Загрузить Файл кода продукта',
    'upload_deactivation_file': 'Загрузить Файл деактивации',
    'activate': 'Активировать',
    'deactivate': 'Деактивировать',
    'activate_license': 'Активировать лицензию',
  },
  ENG: {
    'error_license_already_active': 'This license has been activated previously. Contact Technical Support',
    'error_license_cant_activate': 'Failed to activate license. Contact Technical Support',
    'error_activation_key_not_valid': 'Activation file is not suitable for this license',
    'error_license_is_deactivated': 'Failed to activate license. Contact Technical Support',
    'ACTIVATED': 'Активна',
    'NOT_ACTIVATED': 'Не активна',
    'DEACTIVATED': 'Деактивированна',
    'DEACTIVATED_WITHOUT_KEY': 'Деактивированна без ключа',
    'upload_product_code_file': 'Upload product code file',
    'upload_deactivation_file': 'Upload deactivation file',
    'activate': 'Activate',
    'deactivate': 'Deactivate',
    'activate_license': 'Activate license',
  }
}

export default textConstants;