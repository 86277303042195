import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const EditLicenseModal: React.SFC<Props> = props => {
  const { children, open, handleClose } = props;
  return(
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>Изменить</DialogTitle>
      
      <DialogContent>
        <DialogContentText>
          Введите новое значение
        </DialogContentText>
        { children }
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          Отмена
        </Button>
        <Button form="formChangeClient" value="submit" type="submit">
          Изменить
        </Button>
      </DialogActions>
  </Dialog>
  );
};

export default EditLicenseModal;