export const disableSymbols = (event: any) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (/\+|-|\.|e/.test(keyValue)) {
    event.preventDefault();
  }
};

const maxLength = (max: number) => (value: any) => 
  value && value.length > max ? ` Не более ${max} цифр` : undefined;

export const maxLength6 = maxLength(6);

export const positiveNumber = (value: string) => !isNaN(Number(value)) && Number(value) <= 0 ? 'Число должно быть больше 0' : undefined;

export const banSymbols = (value: string) => {
  if (value) {
    if (value.indexOf('e') > 0 || value.indexOf('.') > 0 || value.indexOf('-') > 0 || value.indexOf('+') > 0) {
      return 'Must be only numbers';
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const maxValue = (max: number) => (value: string) => value && Number(value) > max ? `Не более ${max}` : undefined;

export const requiredStr = (value: string) => value && value.trim() ? undefined : 'Обязательное поле';
