export const tgreen100 = '#A6CF39';
export const tgreen80 = '#B8D961';
export const tgreen60 = '#CAE288';
export const tgreen40 = '#DBECB0';
export const tgreen20 = '#EDF5D7';
export const tgreen10 = '#F6FAEB';
export const tgreen100T = 'rgba(166, 207, 57, 0.6)';

export const tgrey100 = '#282C2E';
export const tgrey80 = '#535658';
export const tgrey60 = '#7E8082';
export const tgrey40 = '#A9ABAB';
export const tgrey20 = '#D4D5D5';
export const tgrey100T = 'rgba(40, 44, 46, 0.6)';
export const tgreyBG = '#EBEBEB';
export const tgreyBD = '#DEDEDE';
export const tgreyM = '#242424';
export const tgreyR = '#F5F5F5';
export const tgreySUB = '#919191';

export const tred100 = '#FF0012';
export const tred80 = '#FF3341';
export const tred60 = '#FF6671';
export const tred40 = '#FF99A0';
export const tred20 = '#FFCCD0';
export const tred60T = 'rgba(255, 102, 113, 0.6)';

export const tblue100 = '#001760';
export const tblue80 = '#334580';
export const tblue60 = '#6674A0';
export const tblue40 = '#99A2BF';
export const tblue20 = '#CCD1DF';

export const torange100 = '#FD8402';
export const torange80 = '#FD9D35';
export const torange60 = '#FEB567';
export const torange40 = '#FECE9A';
export const torange20 = '#FFE6CC';

export const tblack = '#000000';
export const twhite = '#ffffff';
export const tgrey8 = '#EEEEEF';
export const tgrey6 = '#F2F3F3';
export const tgrey4 = '#FFFEFE';
export const tgreyS = '#535658';

export const complitechBlue = '#5cabd6';