import * as React from 'react';
import { connect } from 'react-redux';
import { initUser, apiUrl } from '../../actions/server';
import { withStyles, createStyles, WithStyles } from '@material-ui/core';
import { tgrey100 } from '../../styles/colors';
import { Dispatch } from 'redux';
import LoginForm, { ValuesConfig } from './LoginForm';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { User } from '../../Models/AppState';
import Header from '../Header/Header';
import { SubmissionError } from 'redux-form';
import validateLogin from '../../actions/login';

const styles = createStyles({
  mainHeader: {
    padding: '14px 24px',
    backgroundColor: tgrey100,
    display: 'flex',
    alignItems: 'center',
  },
  wrapperLoginForm: {
    marginTop: 280
  },
});

interface LoginResponse {
  user: User;
  token: string;
}

interface LoginProps extends WithStyles<typeof styles> {
  dispatch: Dispatch;
}

class Login extends React.Component<LoginProps & RouteComponentProps> {
  componentDidMount () {
    if (this.props.location.search === '?logout') {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      this.props.dispatch({type: 'INIT_USER', payload: null});
    }
  }

  handleSubmit = (values: ValuesConfig) => {
    const { dispatch } = this.props;

    validateLogin(values);
    
    return fetch(`${apiUrl}/api/user/requestToken`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({Email: values.loginUser, Password: values.loginPassword}),
    })
    .then(resp => {
      if (!resp.ok) {
        throw new Error(resp.status.toString());
      } else {
        return resp;
      }
    })
    .then(resp => resp.json())
    .then((json: LoginResponse) => {
      localStorage.setItem('token', json.token);
      localStorage.setItem('currentUser', JSON.stringify(json.user));
      dispatch(initUser(json.user));
      this.props.history.push('/list');
    })
    .catch(error => {
      if (error.message === '401') {
        throw new SubmissionError({
          loginUser: '  ',
          loginPassword: 'Пользатель не найден или неправильный пароль',
        });
      } else {
        throw new SubmissionError({
          loginUser: '  ',
          loginPassword: 'Ошибка сервера',
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Header />
        <section className={classes.wrapperLoginForm}>
          <LoginForm
            onSubmit={this.handleSubmit}
          />
        </section>
      </div>
    );
  }
}

export default connect()(withStyles(styles)(withRouter(Login)));
