import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { History, createBrowserHistory } from 'history';
import { reducer as reduxFormReducer } from 'redux-form';
import mainReducer from './reducers/mainReducer';
import Routes from './Components/Routes/Routes';
import tableReducer from './reducers/tableReducer';

export const history = createBrowserHistory();

const rootReducer = (hist: History) => combineReducers({
  mainReducer: mainReducer,
  form: reduxFormReducer,
  table: tableReducer,
});

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(history), // new root reducer with router state
  composeEnhancers(
    applyMiddleware(),
  )
);

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);
