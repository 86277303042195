import * as React from 'react';
import { FormControl, TextField, FormHelperText, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { tblack, tred60, tgreyBD, tgreyBG, tgreen100, tgreySUB } from '../../styles/colors';
import { disableSymbols, positiveNumber, maxValue, requiredStr } from '../../validation';
import { reduxForm, Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { User, License } from '../../Models/AppState';
import { renderTextField } from '../Common/FormCommon';
import { DatePicker } from 'material-ui-pickers';

const max99 = maxValue(99);

const styles = createStyles({
  formWrapper: {
    padding: '0 25px'
  },
  formList: {
    padding: 0
  },
  formListItem: {
    display: 'flex',
    'align-items': 'center',
    marginTop: '5px',
    '&:first-child': {
      marginTop: 0
    }
  },
  formSubTitle: {
    width: '170px',
    fontSize: '13px',
    color: tgreySUB
  },
  formField: {
    fontSize: '13px'
  },
  formInput: {
    width: '400px',
    height: '30px',
    marginTop: '20px',
    boxSizing: 'border-box',
    padding: '0px 16px',
    border: `1px solid ${tgreyBD}`,
    borderRadius: '2px',
    backgroundColor: tgreyBG,
    fontSize: '13px',
    transition: 'all 0.5s ease-out',
    '&:focus': {
      border: `2px solid ${tgreen100}`,
      boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
    },
  },
  formInputError: {
    border: `2px solid ${tred60}`,
    boxShadow: '0 0 0 0.05rem rgba(0, 0, 0,.16)',
  },
  rootCheckBox: {
    marginLeft: '-15px',
  }
});

const renderNumberUsers = (props: any) => {
  const { style, input, meta: { error}, isDemo } = props;
  return (
    <FormControl error={!!error}>
      <TextField
        disabled={isDemo}
        placeholder="Кол-во камер"
        id="cameraNum"
        type="number"
        autoComplete="off"
        onKeyPress={(event) => disableSymbols(event)}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: error ? `${style.formInput} ${style.formInputError}` : style.formInput,
          },
        }}
        {...input}
      />
      {!!error &&
        <FormHelperText id="name-error-text">{error}</FormHelperText>
      }
      {!error && 
        <FormHelperText
          id="name-error-text"
          style={{color: tblack}}
        >
          Не более 99
        </FormHelperText>
      }
    </FormControl>
  );
};

const renderDataPicker = (props: WrappedFieldProps) => {
  const tomorrow = new Date();
  return(
    <DatePicker
      margin="normal"
      disablePast
      value={props.input.value}
      onChange={props.input.onChange}
      format="dd/MMM/yyyy"
      minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
    />
  );
}

interface AdditionalProps {
  user: User | null;
};

interface StyledProps extends WithStyles<typeof styles> {}

class NewLicenseFormCmp extends React.Component<
  AdditionalProps & StyledProps & InjectedFormProps<License, AdditionalProps
  >> {
  render() {
    const { classes, handleSubmit, user } = this.props;
    return (
      <form id="formNewLicense" onSubmit={handleSubmit} autoComplete="off">
        <ul className={classes.formList}>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Кол-во камер</p>
            <div className={classes.formField}>
              <Field
                name="camAmount"
                component={renderNumberUsers}
                style={classes}
                validate={[ positiveNumber, max99 ]}
                parse={value => Number(value)}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Активна до</p>
            <div className={classes.formField}>
              <Field
                name="expirationDate"
                component={renderDataPicker}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Клиент</p>
            <div className={classes.formField}>
              <Field
                name="companyName"
                type="text"
                component={renderTextField}
                style={classes}
                validate={[requiredStr]}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Парт номер</p>
            <div className={classes.formField}>
              <Field
                name="partNumber"
                type="text"
                component={renderTextField}
                style={classes}
                validate={[requiredStr]}
              />
            </div>
          </li>

          <li className={classes.formListItem}>
            <p className={classes.formSubTitle}>Создал</p>
            <div className={classes.formField}>{user ? user.email : ''}</div>
          </li>
        </ul>
      </form>
    );
  }
}

export default reduxForm<License, AdditionalProps>({form: 'newLicense'})(withStyles(styles)(NewLicenseFormCmp));