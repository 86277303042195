import * as React from 'react';
import {Redirect, Route, RouteComponentProps, RouteProps} from 'react-router-dom';
import { StaticContext } from 'react-router';
import { connect, DispatchProp } from 'react-redux';
import { User, AppState } from '../../Models/AppState';
import { initUser, getAllLicenses, getAllUsers } from '../../actions/server';
import { History } from 'history';

type RouteComponent = React.StatelessComponent<RouteComponentProps<{}>> | React.ComponentClass<any>

const getUser = (): User | null => {
  const stringUser = localStorage.getItem('currentUser');
  let loggedUser = null;
  if (stringUser) {
    loggedUser = JSON.parse(stringUser);
  }
  return loggedUser;
}

class PrivateRoute extends React.Component<DispatchProp & MSTP & RouteProps> {
  initializeData = (loggedUser: User, history: History) => {
    const { dispatch } = this.props;
    dispatch(initUser(loggedUser));
    return Promise.all([getAllLicenses(dispatch, history), getAllUsers(dispatch, history)]);
  }

  renderFn = (Component?: RouteComponent) => (props: RouteComponentProps<any, StaticContext, any >) => {
    if (!Component) {
      return null
    }

    const loggedUser = getUser();

    if (loggedUser) {
      if (!this.props.isInitialized) {
        this.initializeData(loggedUser, props.history)
          .then(() => this.props.dispatch({type: 'DATA_INITIALIZED', payload: true}));
      }

      return <Component {...props} />
    }

    const redirectProps = {
      to: {
        pathname: "/",
        state: {from: props.location},
      },
    }

    return <Redirect {...redirectProps} />
  }

  render() {
    const {component, ...rest} = this.props;
    return(
      <Route {...rest} render={this.renderFn(component)} />
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    isInitialized: state.mainReducer.isInitialized
  }
}

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PrivateRoute);